<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
        <tabs-comp :tabs="tabs" />
        
          <v-row
          class="mainrow m-2"
          :style="`direction:` + lang.dir + `;margin-top:22px !important;`"
        >
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
            <v-row>
              <v-col cols="12" md="2" sm="12">
                <label>اختر {{lang.start_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>اختر {{lang.end_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.mobile}}</label>
                <b-input-group>
                  <b-form-input class="inborder" v-model="mobile" style="background:#efefef;" />
                </b-input-group>
              </v-col>

              <v-col cols="12" md="2" sm="12">
                <label>{{lang.quotation_no}}</label>
                <b-input-group>
                  <b-form-input class="inborder" v-model="quotation_no" style="background:#efefef;" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label></label>
                <b-input-group>
                  <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getQuotations()">{{lang.search}}</b-button>
                </b-input-group>
              </v-col>
            </v-row>
          </form>
          <b-input-group class="mt-3" style="display:none">
            <b-form-input placeholder="Start Date" type="date" v-model="sdate" style="border:1px solid #000;border-radius: 0;"></b-form-input>
            <b-form-input placeholder="End Date"  type="date" v-model="edate" style="border:1px solid #000"></b-form-input>
            <b-form-input placeholder="Customer Mobile"  v-model="mobile" style="border:1px solid #000"></b-form-input>
            <b-input-group-append style="border:1px solid #000">
              <b-button variant="light" style="font-size:14px;color:#000;background:gold" @click="getQuotations()"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="12"
          style="display:none;"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="12"
          class="text-end pt-5"
          >
            <b-button id="multiply-button" @click="addQuot()" :class="`mt-3 multiply-button-`+lang.lalgin" :style="`width:160px;`+lang.lalgin+`:60px;background:transparent;border:none;margin-`+lang.lalgin+`:14px;`" v-b-toggle.add_quotation>
            <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_quotation}}</div></b-button>
            <div style="clear:both"></div>
            <br>
        </v-col>
      
      
        <v-col cols="12" :style="`padding-`+lang.lalgin+`:35px;direction:` + lang.dir + `;margin-top:-25px;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event" 
              fixed-header
          >
            <template slot="body.prepend">  
                <tr>  
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-top:1px solid #000 !important;" :colspan="tblColspan">&#32;</td>
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-bottom :2px solid #bbb !important;border-top:1px solid #000 !important;">&#32;</td>
                </tr>
              </template>
            <template v-slot:item="row">
              <tr>
                <td style="width:100px;text-align:center;">{{ row.item.quot_number }}</td>
                <td :style="`width:100px;text-align:center;`+row.item._customer_type_style">{{ row.item._customer_type }}</td>
                <td style="text-align:center;">{{ row.item.quot_date.substr(0,10) }}</td>
                <td style="text-align:center;">{{ row.item.mobile }}</td>
                <td style="text-align:center;" nowrap>{{ row.item._company_name }}</td>
                <!-- <td style="text-align:center;" nowrap v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ row.item.plate_number }}</td>
                <td style="text-align:center;" nowrap v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ row.item.model }}</td> -->
                <td style="text-align:center;">{{ row.item.total }}</td>
                <td style="text-align:center;">{{ row.item.vat }}</td>
                <td style="text-align:center;">{{ row.item.ftotal }}</td>
                <td style="text-align:center;">{{ row.item.added_by }}</td>
                <td :style='`width:70px;text-align:center;color:#`+row.item._fontColor+` !important;background:#`+row.item._statusbgColor+` !important;`'>{{ row.item._status }}</td>
                <td
                    style="
                      cursor: pointer;
                      width: 85px;
                      border-top: 1px solid #bbb !important;border-bottom: 1px solid #bbb !important;
                    "
                    class="backBlue"
                  >
                  <div style="color:#FFF !important;text-align:center" v-b-toggle.viewQuotation @click="doit(row.item.id)">{{lang.view}}</div>
                  <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                </td>
              </tr>
            </template>
            <template slot="body.append" v-if="$store.state.licenseType.showCardNums">
                <tr>  
                  <td style="font-size:.1rem !important;background:#fff !important;border:none !important;" :colspan="tblColspan">&#32;</td>
                  <td style="font-size:.1rem !important;background:#fff !important;border:none !important;">&#32;</td>
                </tr>
                <tr class="black--text">
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backBlack" style="font-size:1.2em;text-align:center;">{{lang.totals}}</th>
                    <th class="backBlack" style="font-size:1.2em;text-align:center;">{{ $convertToComa(totaling.total,$store.state.licenseType.moneyComma) }}</th>
                    <th class="backBlack" style="font-size:1.2em;text-align:center;">{{ $convertToComa(totaling.vat,$store.state.licenseType.moneyComma) }}</th>
                    <th class="backBlack" style="font-size:1.2em;text-align:center;">{{ $convertToComa(totaling.ftotal,$store.state.licenseType.moneyComma) }}</th>
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backEmpty" style="border:none !important;"></th>
                    <th class="backEmpty" style="border:none !important;"></th>
                </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
                color="#000"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      
    </v-row>
    </div>
    <viewQuotation ref="viewQuotation" />
    <add-quotation ref="addQuot" :cust='custo'></add-quotation>
    <Footer />
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
        <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import AddQuotation from '@/components/addQuotation.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import viewQuotation from '@/components/viewQuotation.vue'
import ExportsPrint from '@/components/exports-print.vue';
import {SnotifyPosition} from 'vue-snotify'
export default{
    components: {viewQuotation, AddQuotation, TabsComp, HeaderPortrate,Footer,ExportsPrint },
    data() {
        return {
            custo:[],
            // sdate: '',
            // edate: '',
            mobile: '',
            search: '',
            page: 1,
            pageCount:0,
            
            tablerows: [],
            lstatus: 1,
            quotation_no: '',
            sd: {
              day: 1,
              month: (new Date()).getMonth() + 1,
              year: (new Date()).getFullYear(),
            },
            ed: {
              day: (new Date()).getDate() + 1,
              month: (new Date()).getMonth() + 1,
              year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tblColspan: function(){
          let colspan = 10;
          // if(this.$store.state.licenseType.showDealing){
          //   colspan = 1
          // }
          return colspan;
        },
        totaling: function(){ 
          let t= {
            total: 0,
            vat: 0,
            ftotal: 0
          }
          for(let i=0;i<this.tablerows.length;i++){
            t.total = parseFloat(t.total) + parseFloat(this.tablerows[i].total)
            t.vat = parseFloat(t.vat) + parseFloat(this.tablerows[i].vat)
            t.ftotal = parseFloat(t.ftotal) + parseFloat(this.tablerows[i].ftotal)
          }
          return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},  
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        items: function() {
          return {
                    text: this.lang.quotations,
                    disabled: true,
                    href: '/quotations',
                }
        },
        tabs: function () {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers: function () {
            let headers = [];
            headers.push({
              text: this.lang.quotation_no, align: 'center',
              filterable: false, value: 'quot_number',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.customer_type, align: 'center',
              filterable: false, value: 'customer_type',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.quot_date, align: 'center',
              filterable: false, value: 'quot_date',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.mobile, align: 'center',
              filterable: false, value: 'mobile',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.customer_name, align: 'center',
              filterable: false, value: '_company_name',
              sortable: false,
              class:'backBlack'
            });

            // if(this.$store.state.licenseType.cars || this.$store.state.licenseType.carinfo){
            //   headers.push({
            //     text: this.lang.plate_number, align: 'center',
            //     filterable: true, value: 'plate_number',
            //     sortable: false,
            //     class:'backBlack'
            //   });
            //   headers.push({
            //     text: this.lang.model, align: 'center',
            //     filterable: true, value: 'model',
            //     sortable: false,
            //     class:'backBlack'
            //   });
            // }

            headers.push({
              text: this.lang.total, align: 'center',
              filterable: false, value: 'total',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.vat, align: 'center',
              filterable: false, value: 'vat',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.ftotal, align: 'center',
              filterable: false, value: 'ftotal',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.added_by, align: 'center',
              filterable: false, value: 'added_by',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.status, align: 'center',
              filterable: false, value: '_status',
              sortable: false,
              class:'backBlack'
            });
            headers.push({
              text: this.lang.action, align: 'center',
              filterable: false,
              sortable: false,
              class:'backBlue'
            });
            return headers;
        }
    },
    methods: {
      collectSearch(){
        return {
          module: 'quotations',
          elements: {
            mobile: this.mobile,
            sdate: this.sdate,
            edate: this.edate,
            quotation_no: this.quotation_no,
            branchid: localStorage.getItem('defBranch'),
            financeYear: localStorage.getItem('financeYear'),
          }
        }
      },
      getStartDate(){
        let d = (new Date()).getDate();
        let m = (new Date()).getMonth() + 1;
        let y = (new Date()).getFullYear();
        if(parseInt(d) < 30){
          d = parseInt(30) - parseInt(d);
          if(parseInt(m) == 1){
            m = 12
            y = parseInt(y) - 1
          }
          else if(parseInt(m) > 1){
            m = parseInt(m) - 1;
          }
        }else if(parseInt(d) == 30){
          d = 1;
        }else{
          d = parseInt(d) - parseInt(30);
        }
        this.sd.day = 1;
        this.sd.month = 1;
        this.sd.year = y;
      },
      addQuot(){
        this.$refs.addQuot.id = 0;
        this.$refs.addQuot.tbrows = [];
        this.$refs.addQuot.car = {
                model: '',
                plate_number: '',
                color: '',
                made_year: ''
            }
        this.$refs.addQuot.newrow = {
                itemcode: '',
                description: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: '',
                discount: '',
            }
        this.$refs.addQuot.cars = [{
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
            }]
        this.$refs.addQuot.customer.id=0;
        this.$refs.addQuot.customer.mobile='';
        this.$refs.addQuot.customer.full_name='';
        this.$refs.addQuot.customer.cctype=2;
        this.$refs.addQuot.company.fullname='';
        this.$refs.addQuot.company.crt_number='';
        this.$refs.addQuot.company.vatid='';

        this.$refs.addQuot.getName();
        this.$refs.addQuot.changeCusType(1);

      },
      preparedata(){
          let exdata = {
              list: "quotationList",
              title: this.lang.quotations,
              data: [],
          }
          for(let i=0;i<this.tablerows.length;i++){
              exdata.data.push(this.tablerows[i]);
          }
          // // console.log(exdata);
          this.$refs.exportbar.exportdata = exdata;
          this.$refs.exportbar.sdate = this.sdate;
          this.$refs.exportbar.edate = this.sdate;
          
          this.$refs.exportbar.exporttitle = "";
          this.$refs.exportbar.palte_number = '';
        },
        doit(id) {
          this.$refs.viewQuotation.quotid = id
          this.$refs.viewQuotation.getQuotations()
        },
        gParams(){
            if(String(this.$route.params.status) == 'undefined'){
                this.$route.params.status = 1;
            }
        },
        getQuotations(status) {
            // let message = "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة";
            // this.$snotify.error(message, 'تنبيه', {
            //     timeout: 1000000,
            //     showProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     position: SnotifyPosition.centerCenter,
            //     buttons: [
            //         {
            //             text: this.lang.close, 
            //             action: (toast) => {
            //                 this.$snotify.remove(toast.id); 
            //             } 
            //         },
            //     ]
            // });
            // this.invoice.payment.cash = this.full_ftotal
            // if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
            this.$store.state.showLoading = true;
            this.lstatus = status;
            const post = new FormData();
            post.append('type', "getQuotations");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[sdate]',this.sdate)
            post.append('data[edate]',this.edate)
            post.append('data[mobile]',this.mobile)
            post.append('data[status]',status);
            post.append('data[quotation_no]',this.quotation_no);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
              this.$store.state.showLoading = false;
                const res = response.data;
                // // console.log(res);
                this.tablerows = res.results.data;
            })

        }
    },
    created() {
        this.getStartDate();
        this.getQuotations(1);
    },
}
</script>
<style>
.mytab{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mytab2{
  width:100%;
  background:rgb(4, 112, 155) !important;
  font-size:.8rem;
  color:#FFF;
  border:0px solid #fff;
}
.mytab4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mytab3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mytab1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.mytab5{
  width:100%;
  font-size:.8rem;
  background:#339cfe !important;
  border:0px solid #fff;
}
.mytab6{
  width:100%;
  font-size:.8rem;
  background:#fe3344 !important;
  border:0px solid #fff;
}
input[type=date]:invalid::-webkit-datetime-edit {
    color: #000;
    font-weight: bold;
}
</style>