<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="88%" :style="`direction:`+lang.ldir" right :title="lang.add_invoice" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{lang.add_invoice}}</span>
        </div>
        <div @click="hide" id="HideMeT" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom backBlack p-1" :style="`display:none;color:#fff !important;direction:` + lang.dir">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{lang.customer_type}}</b-button>
                <b-button style="font-weight: bolder;width:150px;font-size:1.1rem !important" id="QcInd" :class="`dispFlex btn btn-small redColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="Qindiv"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                <b-button style="font-weight: bolder;width:150px;font-size:1.1rem !important" id="QcCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="Qcomps"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down xhideme"></i></b-button>
                <b-button style="font-weight: bolder;width:150px;font-size:1.1rem !important" id="QcGov" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="Qgoves" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down xhideme"></i></b-button>
            </div>
            <v-row v-if="$store.state.licenseType.cars || $store.state.licenseType.bill">
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <label>{{lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                
            </v-row>
  
            <v-row v-if="showcamp">
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <label>{{lang.projectname}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                     <label>{{lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_name"
                        v-model="company.fullname"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.crt_number"
                        v-model="company.crt_number"
                        >{{ company.crt_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.comapny_address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.comapny_address"
                        v-model="company.address"
                        >{{ company.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.building_no"
                        v-model="company.building_no"
                        >{{ company.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.street_name"
                        v-model="company.street_name"
                        >{{ company.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_number"
                        v-model="company.branch_number"
                        >{{ company.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.district"
                        v-model="company.district"
                        >{{ company.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.zipcode"
                        v-model="company.zipcode"
                        >{{ company.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.city"
                        v-model="company.city"
                        >{{ company.city }}</b-form-input>
                </v-col>
            </v-row>
            <v-row v-if="showGov">
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.entity_name"
                        v-model="company.entity_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_name"
                        v-model="company.branch_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12" xs="12">
                    <label>{{lang.request_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.request_number"
                        v-model="company.request_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12" xs="12">
                    <label>{{lang.order_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.order_number"
                        v-model="company.order_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12" xs="12">
                    <label>{{lang.po_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.po_number"
                        v-model="company.po_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{lang.po_date}}</label>
                    <v-dialog
                        ref="dialogd"
                        v-model="modal"
                        :return-value.sync="company.po_date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <!-- <label>{{lang.po_date}}</label> -->
                        <b-form-input
                            id="input-live"
                            v-model="company.po_date"
                            aria-describedby="input-live-help input-live-feedback"
                            prepend-icon="mdi-calendar"
                            :placeholder="lang.po_date"
                            readonly
                            trim
                            v-bind="attrs"
                            v-on="on"
                            class="input-sm inborder"
                        ></b-form-input>
                        
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogd.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
            <div  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-row v-for="(item,index) in cars" :key="index">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.plate_number}}</label> -->
                                <label>{{lang.plate_number}}</label>
                                <div class="plateNumber">
                                    <b-form-input class="inborder" @change="updatPlate(index)" v-model="cars[index].pl.c1" style="background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" @change="updatPlate(index)" v-model="cars[index].pl.c2" style="border-inline:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" @change="updatPlate(index)" v-model="cars[index].pl.c3" style="border-inline-end:none !important;background:#fff700 !important;width:30px;background:#efefef;" />
                                    <b-form-input class="inborder" @change="updatPlate(index)" v-model="cars[index].pl.text" style="background:#fff700 !important;width:60px;background:#efefef;" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="2" sm="4" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.car_model}}</label> -->
                                <label>{{lang.car_model}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="lang.car_model"
                                    v-model="cars[index].model"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="lang.car_model"
                                    v-model="cars[index].model"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{lang.car_year}}</label> -->
                                <label>{{lang.car_year}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="lang.car_year"
                                    v-model="cars[index].made_year"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="lang.car_year"
                                    v-model="cars[index].made_year"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <label>{{lang.car_color}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.car_color"
                                    v-model="cars[index].color"
                                    >{{ car.color }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.vin_number}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.vin"
                                    v-model="cars[index].vin"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.engine_meter}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.engine_meter"
                                    v-model="cars[index].engine_meter"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{lang.next_oil_change}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="lang.next_oil_change"
                                    v-model="cars[index].next_oil_change"
                                    ></b-form-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="1" v-if="index!=0">
                        <v-btn style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: red !important;color:#fff !important;padding: 0 !important;" @click="deleteCar(index)">حذف</v-btn>
                    </v-col>
                    <v-col cols="1" v-else>
                        <v-btn style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: green !important;color:#fff !important;padding: 0 !important;" @click="addNewCar()">{{ lang.add }}</v-btn>
                    </v-col> -->
                </v-row>
            </div>

            <v-row class="mt-2">
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.invoice_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.invtype"
                        :options="invtypes"
                        :label="lang.invoice_type"
                        @change="InvType()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.vat_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.vtype"
                        :options="vattype"
                        :label="lang.vat_type"
                        @change="changeVatType()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.payment_method}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.paytype"
                        :options="invoice.paytypes"
                        :label="lang.payment_method"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <label>{{lang.chose_from_list}} {{lang.saler_name}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.userid"
                        :options="invoice.users"
                        :label="lang.saler_name"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12" v-if="$store.state.caneditinvoicedate">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="invoice.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <label>{{lang.invoice_date}}</label>
                        <b-form-input
                            id="input-live"
                            v-model="invoice.date"
                            aria-describedby="input-live-help input-live-feedback"
                            prepend-icon="mdi-calendar"
                            :placeholder="lang.invoice_date"
                            readonly
                            trim
                            v-bind="attrs"
                            v-on="on"
                            class="input-sm inborder"
                        ></b-form-input>
                        
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12" v-else>
                    <label>{{lang.invoice_date}}</label>
                    <b-form-input
                        class="inborder"
                            v-model="invoice.date"
                            :label="lang.invoice_date"
                            prepend-icon="mdi-calendar"
                            readonly
                        >{{ invoice.date }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="8" xs="12">
                    <label>{{lang.notes}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="lang.invoice_notes"
                        v-model="invoice.notes"
                        >{{ invoice.notes }}</b-form-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-window>{{lang.invoice_details}}</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width:120px;" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo" class="text-center backBlack">{{lang.plate_number}}</th>
                                <th class="text-center backBlack">{{lang.item_code}}</th>
                                <th style="width:35%" class="text-center backBlack">{{lang.description}}</th>
                                <th class="text-center backBlack">{{lang.qtty}}</th>
                                <th class="text-center backBlack">{{lang.item_price}}</th>
                                <th class="text-center backBlack">{{lang.discount}}</th>
                                <th class="text-center backBlack">{{lang.total}}</th>
                                <th class="text-center backBlack">{{lang.vat}}</th>
                                <th class="text-center backBlack">{{lang.ftotal}}</th>
                                <th class="text-center backBlack"></th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{ item.plate_number }}</td>
                                <td>{{ item.itemcode }}</td>
                                <td><b-form-input v-model="tbrows[index].item_name" />
                                    <div><b-form-textarea v-model="tbrows[index].description" /></div>
                                </td>
                                <td><b-form-input v-model="tbrows[index].qty" @change="changeVatType()" /></td>
                                <td><b-form-input v-model="tbrows[index].item_price" @change="changeVatType()" /></td>
                                <td><b-form-input v-model="tbrows[index].discount" @change="changeVatType()" /></td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td style="">
                                    <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr>
                                <td v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        :options="currentCars"
                                        v-model="newrow.plate_number">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.itemcode"
                                        @change="getProducts()"
                                        >
                                    </b-form-input>
                                </td>
                                <td>
                                    <v-combobox
                                        v-model="newrow.item_name"
                                        :items="itemsworkdes"
                                        small-chips
                                        
                                    ></v-combobox>
                                    <b-form-textarea
                                        v-model="newrow.description"
                                        small-chips
                                        class="inborder"
                                    ></b-form-textarea>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        type="number"
                                        id="newitemqty" style="width:50px;"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                        type="number" style="width:50px;"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.discount"
                                        @change="calcKit()"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td class="text-center" style="">
                                    <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow()">{{lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo" class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <td class="text-center"> % {{lang.discount}}</td>
                                <td class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td class="text-center backEmpty"></td>
                                <td class="text-center backEmpty"></td>
                                <th class="text-center backEmpty"></th>
                            </tr>
                            <tr>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backBlack">{{lang.total}}</th>
                                <th class="text-center backGreen">{{ full_total }}</th>
                                <th class="text-center backRed">{{ full_vat }}</th>
                                <th class="text-center backBlack">{{ full_ftotal }}</th>
                                <th class="text-center backEmpty"></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> تأمين</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.insurance"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">الاجمالي بعد التامين</th>
                                <th class="text-center">{{ full_total_in }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr> -->
                            <tr v-if="invoice.invtype != 1">
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty" colspan="5" style="color:#000 !important;">
                                    <label>{{lang.payment_amount}}</label>
                                    <b-form-input
                                        class="inborder"
                                        :label="lang.payment_amount"
                                        v-model="invoice.payment.cash"
                                        style="background:#c3d9ff;width:150px"
                                        >{{ invoice.payment.cash }}</b-form-input>
                                </td>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row style="display:none">
                <v-col cols="12" md="4" sm="6" xs="12">
                    <label>{{lang.payment_amount}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.payment_amount"
                        v-model="invoice.payment.cash"
                        type="number"
                        >{{ invoice.payment.cash }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <label v-if="invoice.paytype == 2 && invoice.paytype == 3">{{lang.span_payment  }}</label>
                    <b-form-input
                        class="inborder"
                        v-if="invoice.paytype == 2 && invoice.paytype == 3"
                        :label="lang.span_payment"
                        v-model="invoice.payment.span"
                        type="number"
                        >{{ invoice.payment.span }}</b-form-input>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addInvoice()" id="addBTN" variant="danger" class="ma-2 btn-sm" style="border:1px solid green !important;width:160px;background:green !important;color:#fff !important;">{{lang.save}}</b-button>
        </div>
      
      </template>
        </b-sidebar>
        </v-form>
        
      </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    props:[
        'custom','prods','cardid'
    ],
    data() {
        return {
            ecars: [],
            quotID: 0,
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
            },
            cars: [{
                pl: {
                    c1: '',
                    c2: '',
                    c3: '',
                    text: ''
                },
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            }],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            showGov: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                
            },
            invoice:{
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                next_oil_change: '',
                
                invtype:1,
                
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
            },
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            tbrows:[
            ],
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                discount: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
            },
        }
    },
    created() {
        this.getInvSettings();
        // setTimeout(() => {
        //     this.getInfo(); 
        // }, 2000);
        this.getPyamentType();
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        currentCars: function(){
            let t = [{text: this.lang.chose_car, value: ''}]
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        invtypes: function() {
            return  [
                    {
                        text: this.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.lang.paid_invoice,
                        value: 1,
                    },
                ]
        },
        vattype: function() {
            return  [
                    {
                        text: this.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.lang.prices_without_vat,
                        value: 1,
                    },
                ]
        },
        ctype: function() {
            return [
                    {
                        text: this.lang.individual_client,
                        value: 1,
                    },
                    {
                        text: this.lang.company_client,
                        value: 2,
                    }
                ]
        },
        full_total_in: function(){
            let t = 0;
            t = parseFloat(this.full_ftotal) - parseFloat(this.invoice.insurance);

            return this.$RoundNum(t);
        }
    },
    methods: {
        updatPlate(index){
            this.cars[index].plate_number = this.cars[index].pl.c1;
            if(this.cars[index].pl.c2 != ''){
                this.cars[index].plate_number = this.cars[index].plate_number + ' ' + this.cars[index].pl.c2;
            }
            if(this.cars[index].pl.c3 != ''){
                this.cars[index].plate_number = this.cars[index].plate_number + ' ' + this.cars[index].pl.c3;
            }
            if(this.cars[index].pl.text != ''){
                this.cars[index].plate_number = this.cars[index].plate_number + ' ' + this.cars[index].pl.text;
            }
        },
        deleteCar(index){
            this.cars.splice(index,1);
        },
        addNewCar(){
            this.cars.push({
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
            });
        },
        changeCoData(co){
            // // console.log(co);
            this.company = {
                fullname: co.company_name,
                vatid: co.vatid,
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:co.address,
                crt_number: co.crt_number,
                entity_name: co.entity_name,
                branch_name: co.branch_name,
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
            }
        },
        getProducts() {
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].qty > 0){
                        this.newrow.itemcode = response.data.results.data[0].itemcode;
                        this.newrow.description = response.data.results.data[0].name;
                        this.newrow.price = response.data.results.data[0].price;
                        this.newrow.qty = 1;
                        this.calcKit();
                        document.getElementById('newitemqty').focus();
                    }else{
                        this.newrow.itemcode = '';
                        this.newrow.description = '';
                        this.newrow.qty = '';
                        this.newrow.price = '';
                        this.newrow.total = '';
                        let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    this.newrow.itemcode = '';
                    this.newrow.description = '';
                    this.newrow.qty = '';
                    this.newrow.price = '';
                    this.newrow.total = '';
                    let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
                this.calcKit();
            })
        },
        getItemname(){
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                document.getElementById('newitemqty').focus();
            })
            // .then(() => {
            //     // newrow = {
            //     //     itemcode: '',
            //     //     item_name: '',
            //     //     qty: 1,
            //     //     item_price: 0,
            //     //     total: 0,
            //     //     vat: 0,
            //     //     ftotal: 0
            //     // }
            // })
        },
        getInfo(){
            
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.itemsworkdes = [
                
            ];
            this.car = {
                pl: {
                    c1: '',
                    c2: '',
                    c3: '',
                    text: ''
                },
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';
            this.invoice.payment.cash = 0;
            this.invoice.payment.span = 0;
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
            
            this.tbrows = [
            ];
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: 0,
                vat: 0,
                ftotal: 0
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.lang.individual_client;
            }
            // // console.log("asdfasf",this.tbrows.length);
            // // console.log([this.full_ftotal,this.invoice.payment.cash]);
            let x = 1;
            if(this.invoice.payment.cash > this.full_ftotal){
                let message = "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                return false;
            }
            if(this.tbrows.length == 0){
                let message = this.lang.you_can_not_add_empty_invoice;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            // building_no: '',
            // street_name: '',
            // branch_number: '',
            // district: '',
            // zipcode: '',
            // city:'',
            // address:'',
            // crt_number: ''
           let address = true;
           if(this.company.address == ''){
                if(
                    this.company.building_no == '' && this.company.street_name == '' &&
                    this.company.branch_number == '' && this.company.district == '' &&
                    this.company.zipcode == '' && this.company.city == ''
                ){
                    address = false;
                }
           }
        //    if(this.customer.cctype == 2 && (this.company.fullname == '' || !address || this.company.crt_number == '')){
           if(this.customer.cctype == 2 && (this.company.fullname == '' || !address)){
               let message = this.lang.company_ifnormation_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }
           if(this.$store.state.licenseType.carInfoRequired){
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number == '' || this.cars[i].modal == '' || this.cars[i].made_year == ''){
                    let message = this.lang.car_ifnormation_required;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    // return false;
                }
            }
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            // // console.log(this.company);
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(let i = 0; i < this.cars.length;i++){
                for(const [key, value] of Object.entries(this.cars[i])){
                    post.append('data[cars]['+i+']['+key+']]',value);
                }
            }
            // for(const [key, value] of Object.entries(this.car)){
            //     post.append('data[car]['+key+']]',value);
            // }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][card_type]",this.invoice.card_type);
            post.append("data[inovice][insurance]",this.invoice.insurance);
            post.append("data[inovice][projectname]",this.company.projectname);
            // post.append("data[inovice][engine_meter]",this.invoice.engine_meter);
            // post.append("data[inovice][next_oil_change]",this.invoice.next_oil_change);
            post.append("data[cardid]",this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 
                // // console.log("addinvoice",resposne.data);
                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                let message = this.lang.invoice_added;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.printPDF(resposne.data.results.data.results.newID);
                document.getElementById('HideMeT').click();
                this.resetInvoice();
                this.updateQuot();
                // this.$parent.getCurrentCards();
                
            })
        },
        updateQuot(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "updateQuot"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.quotID);
            post.append("data[status]",2);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 
                // // console.log(resposne.data)
                this.$parent.$parent.getQuotations()
            })            
        },
        printInvo(id){
            window.open('../../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../../api/print.php?invid='+id;
        },
        changePtype(){
            // let tbs = [];
            //  if(this.tbrows.length != 0){
            //     for(let i = 0; i < this.tbrows.length; i++){
            //         let fftotal = parseFloat(this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty);
            //         fftotal = this.$RoundNum(fftotal);
            //         const vs = this.$calcVat(fftotal,this.invoice.vtype);
            //         const itm = {
            //             item_name: this.tbrows[i].item_name,
            //             qty: this.tbrows[i].qty,
            //             item_price: this.tbrows[i].item_price,
            //             total: vs.tot,
            //             vat: vs.vat,
            //             ftotal: vs.ftot
            //         }
            //         tbs.push(itm);
            //     }
            // }
            // this.tbrows = tbs;
            // this.calcAll();
            // this.calcKit();
        },
        changeVatType(){
            // // console.log("---");
            this.calcKit();
            this.calcAll();
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            let rows = [];
            for(let i=0;i<this.tbrows.length;i++){
                let total = 0;
                if(!this.$store.state.licenseType.rowDiscountInTotal){
                    const pr = this.$RoundNums(parseFloat(this.tbrows[i].item_price) - parseFloat(this.tbrows[i].discount));
                    total = this.$RoundNums(parseFloat(pr) * parseFloat(this.tbrows[i].qty));
                }
                else{
                    const pr = this.$RoundNums(parseFloat((this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty)) - parseFloat(this.tbrows[i].discount));
                    total = parseFloat(pr)
                }
                
                const tv = this.$calcVat(total,this.invoice.vtype);
                // const ftotal = this.$RoundNums(parseFloat(vat) + parseFloat(total),2)
                this.tbrows[i].total = tv.tot;
                this.tbrows[i].vat = tv.vat;
                this.tbrows[i].ftotal = tv.ftot;
            }
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > 30){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0){
                this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
                this.invoice.payment.cash = this.full_ftotal;
                this.invoice.payment.span = 0;
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = this.full_ftotal;
            }
            if(this.invoice.invtype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = 0;
            }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = this.full_ftotal;
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = this.full_ftotal;
                    this.invoice.payment.cash = 0;
                }
            }else{
                this.invoice.payment.span = 0;
                this.invoice.payment.cash = 0;
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            let price = 0;
            if(!this.$store.state.licenseType.rowDiscountInTotal){
                const nprice = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount);
                price = +nprice * +this.newrow.qty;
            }else{
                const nprice = (parseFloat(this.newrow.item_price) * parseFloat(this.newrow.qty)) - parseFloat(this.newrow.discount);
                price = nprice;
            }
            
            let vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                let message = this.lang.can_not_add_item_withoud_discription;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                let message = this.lang.item_qtty_must_be_more_than_zero;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                    let message = this.lang.item_price_must_be_more_than_zero;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    return false;
                }
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        this.invoice.users = [{text: this.lang.chose_from_list, value: 0}];
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.itemsworkdes.push(res.results.data.products[i].itemcode + ' - ' + res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        async getName(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            const response = await axios.post(
                this.$SAMCOTEC.r_path, post
            )
            if(response && response.data) {
                const res = response.data;
                if(res.error.number == 200){
                    this.customer.id = res.results.data['id'];
                    this.customer.mobile = res.results.data['mobile'];
                    this.customer.full_name = res.results.data['full_name'];
                    this.customer.customerid = res.results.data['customerid'];
                    this.company.fullname = res.results.data['company_name'];
                    this.company.vatid = res.results.data['company_vatid'];
                    this.company.crt_number = res.results.data['crt_number'];
                    this.company.address = res.results.data['address'];
                    this.company.city = res.results.data['city'];
                    this.company.zipcode = res.results.data['zipcode'];
                    this.company.district = res.results.data['district'];
                    this.company.branch_number = res.results.data['branch_number'];
                    this.company.street_name = res.results.data['street_name'];
                    this.company.building_no = res.results.data['building_no'];
                    if(this.company.address == 'undefined' || this.company.address == ''){
                        this.company.address = this.company.building_no + ","; 
                        this.company.address += this.company.street_name + " ";
                        this.company.address += this.company.branch_number + " - " + this.company.zipcode + ", ";
                        this.company.address += this.company.district + " - "+ this.company.city;
                    }
                }
            }
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.invoice.paytypes.push({
                        text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.invoice.paytype = res.results.data[0].id;
            })
        },
        getCar(plate_number,index){
            // // console.log("index",index)
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','carInfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[plate_number]',plate_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    let cars = [];
                    if(res.error.number == 200){
                        let car = {
                            plate_number:res.results.data.plate_number,
                            made_year:res.results.data.made_year,
                            model:res.results.data.model,
                            vin:res.results.data.vin,
                            id:res.results.data.id,
                            color:res.results.data.color,
                        }
                        this.newrow.plate_number = res.results.data.plate_number;
                        // this.updateCars(car);
                        this.cars[index] = car;
                        
                        this.addNewCar();
                        this.cars.pop()
                    }else{
                        this.newrow.plate_number = plate_number;
                    }
                }
            )
        },
        
        changeCusType(id){

            if(id == 0){
                this.customer.cctype = 1;
                this.showcamp = false;
                this.showGov = false;
                document.getElementById('Qindiv').classList.remove('xhideme');
                document.getElementById('Qcomps').classList.add('xhideme');
                document.getElementById('Qgoves').classList.add('xhideme');

                document.getElementById('QcInd').classList.remove('redColor');
                document.getElementById('QcCom').classList.remove('redColor');
                document.getElementById('QcGov').classList.remove('redColor');

                document.getElementById('QcInd').classList.add('redColor');
                document.getElementById('QcCom').classList.add('weightColor');
                document.getElementById('QcGov').classList.add('weightColor');

            }else if(id == 1){
                this.customer.cctype = 2;
                this.showcamp = true;
                this.showGov = false;
                document.getElementById('Qcomps').classList.remove('xhideme');
                document.getElementById('Qcomps').classList.remove('hideme');
                document.getElementById('Qindiv').classList.add('xhideme');
                document.getElementById('Qgoves').classList.add('xhideme');

                document.getElementById('QcInd').classList.remove('redColor');
                document.getElementById('QcCom').classList.remove('redColor');
                document.getElementById('QcGov').classList.remove('redColor');

                document.getElementById('QcInd').classList.add('weightColor');
                document.getElementById('QcCom').classList.add('redColor');
                document.getElementById('QcGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customer.cctype = 3;
                this.showGov = true;
                this.showcamp = false;
                document.getElementById('Qgoves').classList.remove('xhideme');
                document.getElementById('Qcomps').classList.add('xhideme');
                document.getElementById('Qindiv').classList.add('xhideme');

                document.getElementById('QcInd').classList.remove('redColor');
                document.getElementById('QcCom').classList.remove('redColor');
                document.getElementById('QcGov').classList.remove('redColor');

                document.getElementById('QcInd').classList.add('weightColor');
                document.getElementById('QcCom').classList.add('weightColor');
                document.getElementById('QcGov').classList.add('redColor');

            }
            // this.changeCtype();
        },
    },
}
</script>
<style>
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.xhideme {
    display: none !important;
}
</style>